import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import logo from "../../assets/img/ClearKeyLogoWhite.svg";
import { Auth } from "aws-amplify";

class Footer extends Component {
  constructor() {
    super();
    this.state = {
      loggedIn: false,
      nonApplicablePageForFooter: false,
      user: null,
    };
  }

  checkIfLoggedIn = async () => {
    try {
      let r = await Auth.currentAuthenticatedUser();
      this.setState({ loggedIn: true, user: r });
    } catch {
      this.setState({ loggedIn: false });
    }
  };

  checkIfNonApplicablePageForFooter = async () => {
    const location = this.props.location.pathname;

    const nonApplicablePages = ["/login", "/register"];

    if (nonApplicablePages.includes(location)) {
      this.setState({ nonApplicablePageForFooter: true });
    }
  };

  componentDidMount() {
    this.checkIfLoggedIn();
    this.checkIfNonApplicablePageForFooter();
  }

  render() {
    // eslint-disable-next-line
    const { loggedIn, nonApplicablePageForFooter, user } = this.state;

    return (
      <footer
        className={
          "acr-footer footer-2 " + (loggedIn ? "" : "footer-2-extra-margin")
        }
        style={{
          borderTopStyle: "solid",
          borderTopWidth: "1px",
          borderTopColor: "rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Hanging Get Started Box */}
        {loggedIn || nonApplicablePageForFooter ? null : (
          <div>
            <div className="container footer-top__ready">
              <div className="footer-top__ready-content">
                <div className="ready__text">
                  <div className="ready__text-title">Are you ready!</div>
                  <br />
                  <div className="ready__text-sub-text">
                    Get started today to experience the Clear Key difference the
                    next time you buy, sell or list a property.
                  </div>
                </div>
                <div className="ready__button">
                  <Link to="/login" className="btn-custom primary">
                    I’m ready to get started!
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className={
            "footer-middle " +
            (loggedIn || nonApplicablePageForFooter
              ? ""
              : "footer-extra-padding")
          }
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-sm-12 footer-widget">
                <div className="footer-logo">
                  {" "}
                  <img src={process.env.PUBLIC_URL + logo} alt="logo" />{" "}
                </div>
                {/* Social Media Start */}
                {/* <h5 className="footer-section-title">Get Social</h5>
								<div className="footer-social">
									<span className="menu-item">
										{" "}
										<Link to="#">
											{" "}
											<i className="flaticon-facebook" />
											Facebook
										</Link>{" "}
									</span>
									<span className="menu-item">
										{" "}
										<Link to="#">
											{" "}
											<i className="flaticon-linkedin" /> Linkedin {" "}
										</Link>{" "}
									</span>
									<span className="menu-item">
										{" "}
										<Link to="#">
											{" "}
											<i className="flaticon-twitter" /> Twitter {" "}
										</Link>{" "}
									</span>
									<span className="menu-item">
										{" "}
										<Link to="#">
											{" "}
											<i className="flaticon-instagram" /> Instagram {" "}
										</Link>{" "}
									</span>
								</div> */}
                {/* Social Media End */}
              </div>
              <div className="col-lg-2 offset-lg-1 col-sm-3 footer-widget">
                <h5 className="footer-section-title">Menu</h5>
                <ul>
                  <li>
                    {" "}
                    <Link to="/listings">Find Home</Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to={loggedIn ? "/submit-listing" : "/login"}>
                      Add Listing
                    </Link>{" "}
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-sm-3 footer-widget">
                <h5 className="footer-section-title">Information</h5>
                <ul>
                  <li>
                    {" "}
                    <Link to="/about">About Us</Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/contact">Contact Us</Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/faq">FAQ</Link>{" "}
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-sm-3 footer-widget">
                <h5 className="footer-section-title">Legal</h5>
                <ul>
                  <li>
                    {" "}
                    <a href="/legal/cookie_policy">Cookie Policy</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="/legal/terms_of_service">Terms of Service</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="/legal/privacy_policy">Privacy Policy</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="/legal/trademark">Trademark</a>{" "}
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-sm-3 footer-widget">
                <a href="/legal/fair_housing_statement">
                  <h5 className="footer-section-title">
                    Fair Housing Statement
                  </h5>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Footer Middle End */}
        {/* Footer Bottom Start */}
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <p className="m-0">
                  © Copyright {new Date().getFullYear()} -{" "}
                  <Link to="#">Clear Key</Link> All Rights Reserved.
                </p>
              </div>
              <div className="col-lg-3">
                <p className="m-0">
                  Made with{" "}
                  <span
                    role="img"
                    aria-label="heart"
                    className="h5 text-danger"
                  >
                    ♥
                  </span>{" "}
                  in NYC by Dream Team.
                </p>
              </div>
              <div className="col-lg-2">
                <a href="https://aws.amazon.com/what-is-cloud-computing">
                  <img
                    src="https://d0.awsstatic.com/logos/powered-by-aws-white.png"
                    alt="Powered by AWS Cloud Computing"
                    style={{ maxWidth: "80px" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Footer Bottom End */}
      </footer>
    );
  }
}

export default withRouter(Footer);
