import ListingsActionTypes from "./listing.types";
// Requiring the lodash library  
const _ = require("lodash");

const INITIAL_STATE = {
    view: "grid",
    previousFilters: {
        location: "",
        city: "",
        state: "",
        listingStatus: "",
        pricerange: "",
        beds: "",
        bathrooms: "",
        homeType: "",
        yearBuilt: "",
        outdoorAmenities: [],
        homeAmenities: [],
        indoorFeatures: [],
    },
    filters: {
        location: "",
        city: "",
        state: "",
        listingStatus: "",
        pricerange: "",
        beds: "",
        bathrooms: "",
        homeType: "",
        yearBuilt: "",
        outdoorAmenities: [],
        homeAmenities: [],
        indoorFeatures: [],
    },
    listings: [],
};

const listingsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ListingsActionTypes.SELECT_VIEW_TYPE:
            return {
                ...state,
                view: action.payload,
            }
        case ListingsActionTypes.SET_LISTING_FILTERS:
            let value = action.value;
            if (action.value !== "any") {
                return {
                    ...state,
                    filters: {
                        ...state.filters,
                        [action.field]: value,
                    },
                }

            }
            break;
        case ListingsActionTypes.APPLY_FILTERS:
            return {
                ...state,
                previousFilters: action.filters,
            }
        case ListingsActionTypes.REMOVE_FILTER:
            return {
                ...state,
                filters: _.omit(state.filters, action.field)
            }
        case ListingsActionTypes.RESET_FILTERS:
            return {
                ...state,
                filters: INITIAL_STATE.filters,
                previousFilters: INITIAL_STATE.previousFilters,
            }
        case ListingsActionTypes.CANCEL_FILTERS:
            return {
                ...state,
                filters: action.previousFilters,
            }
        case ListingsActionTypes.SELECT_LISTINGS:
            return {
                ...state,
                listings: action.listings,
            }
        case ListingsActionTypes.PAGINATE_LISTINGS:
            return {
                ...state,
                listings: [...state.listings, ...action.listings]
            }
        default:
            return { ...state };
    }
}

export default listingsReducer;