import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Auth, API } from "aws-amplify";
import { getCustomer } from "../../graphql/queries";
import { ConsoleLogger } from '@aws-amplify/core';


function AdditionalDetailsPopup() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(false);

    const [idToken, setIdToken] = useState("");
    const [userCompletedOpenHouseDetails, setUserCompletedOpenHouseDetails] = useState(false);


    useEffect(() => {
        // Get current user if logged in
        if (idToken === "") {
            getCurrentUser();
        }

        // eslint-disable-next-line
    }, [idToken]);


    const getCurrentUser = async () => {
        const data = (await Auth.currentSession()).getIdToken();
        const user = await Auth.currentAuthenticatedUser();
        setIdToken(data);

        const obj = await API.graphql({
            query: getCustomer,
            variables: {
                id: user.attributes.sub,
            },
            authMode: "AWS_IAM",
        });
        let rentOrOwn = null;
        //let rentOrOwn = obj.data.getCustomer.rentOrOwn;
        //console.log(obj.data.getCustomer);
        if (rentOrOwn === null) {
            setShow(true);
        }
        else {

            setUserCompletedOpenHouseDetails(true);
        }
        console.log(rentOrOwn);
    };


    return (
        <>
            <Button style={{ display: "none" }} variant="primary" onClick={handleShow}>

            </Button>
            {
                /*   userCompletedOpenHouseDetails === false && (
                  <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                      <Modal.Title>Additional Details</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                          Would you like to finish setting up your profile? 
                      </Modal.Body>
                      <Modal.Footer>
                      <Button 
                      onClick={(e) => {
                          e.preventDefault();
                          window.location.href='/open-house-questions';
                          }}
                      style={{marginLeft:"50px", marginRight:"50px"}}
                      variant="primary">
                          Complete Profile
                      </Button>
                      </Modal.Footer>
                  </Modal>
                  ) */
            }
        </>
    );


}
export default AdditionalDetailsPopup;

