import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { createBrowserHistory } from "history";
import { useState, useEffect } from 'react';
import QRPopup from "./QRPopup";

const history = createBrowserHistory({ forceRefresh: true });


const Mobilemenu = ({ navtoggleClass }) => {
  const [qrCodeShow, setQrCodeShow] = useState(false);
  
  const resetQRCode = () => {
    setQrCodeShow(false);
  }
  
  const handleClick = () => {
    console.log("logging");
    setQrCodeShow(true);
    navtoggleClass(false);
  };
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  const handleLogout = (event) => {
    event.preventDefault();
    Auth.signOut()
      .then(() => {
        history.push("/login");
        setLoggedIn(false);
      })
      .catch((e) => console.error("Error signing out. " + e));
  };

  const checkIfLoggedIn = async () => {
    try {
      let r = await Auth.currentAuthenticatedUser();
      setLoggedIn(true);
      setUser(r);
    } catch {
      setLoggedIn(false);
    }
  };

  useEffect(() => {
    checkIfLoggedIn();
  }, []);

  const getNextSibling = function (elem, selector) {
    // Get the next sibling element
    var sibling = elem.nextElementSibling;
    // If there's no selector, return the first sibling
    if (!selector) return sibling;
    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
      if (sibling.matches(selector)) return sibling;
      sibling = sibling.nextElementSibling;
    }
  };

  const triggerChild = (e) => {
    let subMenu = "";
    subMenu =
      getNextSibling(e.target, ".submenu") !== undefined
        ? getNextSibling(e.target, ".submenu")
        : null;
    if (subMenu !== null && subMenu !== undefined && subMenu !== "") {
      subMenu.classList = subMenu.classList.contains("d-block")
        ? "submenu"
        : "submenu d-block";
    }
  };

  return (
    <div className="aside-scroll">
      <ul>
        {/* Pages Start */}
        <hr />
        <li className={`menu-item`}>
          <Link to="/" onClick={() => navtoggleClass(false)}>
            <i className={"flaticon-home"} /> Home
          </Link>
        </li>
        <li className={`menu-item `}>
          <Link to="/about" onClick={() => navtoggleClass(false)}>
            <i className={"flaticon-writing-1"} /> About
          </Link>
        </li>
         <li className={`menu-item`}>
          <Link to="/pricing" onClick={() => navtoggleClass(false)}>
            <i className={"flaticon-sold"} /> Pricing
          </Link>
        </li>
         <li
          className={`menu-item menu-item-has-children`}
          onClick={triggerChild}
        >
          <Link onClick={(e) => e.preventDefault()} to="">
            <i className={"flaticon-list"} /> Listings
          </Link>
          <ul className="submenu">
            <li className="menu-item">
              <Link to="/listings" onClick={() => navtoggleClass(false)}>
                Browse Listings
              </Link>
            </li>
            {loggedIn && (
              <li className="menu-item">
                {" "}
                <Link
                  to="/submit-listing"
                  onClick={() => navtoggleClass(false)}
                >
                  Submit Listing
                </Link>{" "}
              </li>
            )}
          </ul>
        </li>
        <li className={`menu-item`}>
          <Link to="/resources" onClick={() => navtoggleClass(false)}>
            <i className={"flaticon-telephone"} /> Resource
          </Link>
        </li>
        <li className={`menu-item`}>
          <Link to="/contact" onClick={() => navtoggleClass(false)}>
            <i className={"flaticon-telephone"} /> Contact Us
          </Link>
        </li>

 

          {loggedIn && (
            <li className="menu-item">
              {" "}
              <Link to="/dashboard" onClick={() => navtoggleClass(false)}>
                <i className={"flaticon-checklist"} /> Dashboard
              </Link>{" "}
            </li>
          )}
          {loggedIn && (
            <li className="menu-item">
              
              {" "}
              <a onClick={handleClick}>
                <i className={"flaticon-key"} />
                CKey 
              </a>
              
              
              {" "}
              
            </li>
          )}
          {qrCodeShow && <QRPopup onHide={resetQRCode}/>}

           {user ? (
            <li
            className={`menu-item menu-item-has-children`}
            onClick={triggerChild}
             >
              <Link onClick={(e) => e.preventDefault()} to="">
                {" "}
                <i className={"flaticon-user"} />
                {user.attributes.email}
              </Link>
              <ul className="submenu" role="menu">
                <li className="menu-item">
                  {" "}
                  <Link to="/profile" onClick={() => navtoggleClass(false)}>
                    Profile
                  </Link>{" "}
                </li>
              </ul>
            </li>
          ) : null}
          {loggedIn ? (
            <li className="menu-item">
              <Link to={"/login"} onClick={handleLogout}>
                <i className={"flaticon-door"} />
                {"Logout"}
              </Link>
            </li>
          ) : (
            <li className="menu-item">
              <Link to={"/login"}>
                <i className={"flaticon-door"} />
                {"Sign Up / Log-In"}
              </Link>
            </li>
          )}
          {/* Pages End */}
          {/* Social Media Start */}
          {/* <li className="menu-section-title text-white">Get Social</li>
                    <li className="menu-item"> <Link to="#"> <i className="flaticon-facebook" />Facebook</Link> </li>
                    <li className="menu-item"> <Link to="#"> <i className="flaticon-linkedin" /> Linkedin </Link> </li>
                    <li className="menu-item"> <Link to="#"> <i className="flaticon-twitter" /> Twitter </Link> </li>
                    <li className="menu-item"> <Link to="#"> <i className="flaticon-instagram" /> Instagram </Link> </li> */}
          {/* Social Media End */}
        </ul>
      </div>
    );
  }


export default Mobilemenu;
