import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { createBrowserHistory } from "history";

import { GetImagesS3 } from "../../js/utilities/getImagesS3";

import "../../../src/assets/css/shared/images.css";

const history = createBrowserHistory({ forceRefresh: true });

export const UserDropdown = ({ user }) => {
	// User Info
	const [idToken, setIdToken] = useState("");
	const [userImgUrl, setUserImgUrl] = useState("");

	// Set Up
	useEffect(() => {
		// Get current user if logged in
		if (idToken === "") {
			getCurrentUser();
		}
		if (idToken !== "") {
			getUserPic();
		}
		// eslint-disable-next-line
	}, [idToken]);

	const getCurrentUser = async () => {
		const data = (await Auth.currentSession()).getIdToken();
		setIdToken(data);
	};

	const getUserPic = async () => {
		const customerId = String(idToken.payload.sub);
		var imageUrl = await GetImagesS3(customerId, "profile-pic");

		if (imageUrl.length > 0) {
			setUserImgUrl(imageUrl[0]);
		}
	};

	const handleLogout = (event) => {
		event.preventDefault();
		Auth.signOut()
			.then(() => {
				history.push("/login");
				this.setState({ loggedIn: false });
			})
			.catch((e) => console.error("Error signing out. " + e));
	};

	return (
		<Fragment>
			<div className="menu-item menu-item-has-children user-center">
				{/* <Link to="/" className="user-dropdown-circle">
					<span className="fas fa-user" style={{ color: "white" }} />
				</Link> */}

				<div className="profile-icon" alt="">
					{userImgUrl !== "" ? (
						<div>
							<img src={userImgUrl} alt="profile-pic" />
						</div>
					) : (
						<div>
							<span className="fas fa-user" />
						</div>
					)}
				</div>

				<ul className="submenu">
					<li className="menu-item welcome-user"> {user.attributes.email} </li>
					<li className="menu-item">
						{" "}
						<Link to="/profile">Profile</Link>{" "}
					</li>
					<li className="menu-item">
						{" "}
						<Link to="/login" onClick={handleLogout}>
							Logout
						</Link>{" "}
					</li>
				</ul>
			</div>
		</Fragment>
	);
};
