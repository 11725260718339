import React, { Component } from 'react';
import CookieConsent from "react-cookie-consent";

//https://www.npmjs.com/package/react-cookie-consent

class CookieNotification extends Component{ 
    render(){
        return (
            <CookieConsent
                location="bottom"
                buttonText="I ACCEPT"
                cookieName="consent-cookie"
                style={{ background: "#65656B" }}
                buttonStyle={{ backgroundColor: "#49A4E2", color: '#FFFFFF', fontSize: "15px", borderRadius: '10px', paddingLeft: '20px', paddingRight: '20px'}}
                expires={150}
                enableDeclineButton
                declineButtonText="Use minimal essential cookies"
                declineButtonStyle = {{backgroundColor:"Transparent",  marginLeft: '10px', textDecoration: 'underline'}}
                flipButtons
                >
                This website uses cookies to enhance the user experience. <a href="/legal/cookie_policy"> Cookie Policy </a>
            </CookieConsent>
        )
    }
}

export default CookieNotification;