const ListingsActionTypes = {
    SELECT_VIEW_TYPE: "SELECT_VIEW_TYPE",
    SET_LISTING_FILTERS: "SET_LISTING_FILTERS",
    REMOVE_FILTER: "REMOVE_FILTER",
    RESET_FILTERS: "RESET_FILTERS",
    CANCEL_FILTERS: "CANCEL_FILTERS",
    APPLY_FILTERS: "APPLY_FILTERS",
    SELECT_LISTINGS: "SELECT_LISTINGS",
    PAGINATE_LISTINGS: "PAGINATE_LISTINGS",
};

export default ListingsActionTypes;