import { Storage } from "aws-amplify";
import { DOT_JPG, PUBLIC } from "../constants/constants";
import {
	agentProfilePictureS3Prefix,
	accountProfilePictureS3Prefix,
	propertyImageS3Prefix,
} from "../utilities/s3Prefixes";
// import { details, features, homeTypes } from "./submit_bid_details";

export const GetImagesS3 = async (ID, type) => {
	let prefix = "";

	if (type === "property") {
		// Define image key prefix in s3
		prefix = propertyImageS3Prefix(ID);
	} else if (type === "agent") {
		prefix = agentProfilePictureS3Prefix(ID);
	} else if (type === "profile-pic") {
		prefix = accountProfilePictureS3Prefix(ID);
	}
	//console.log("prefix -> " + JSON.stringify(prefix));
	// List all images in bucket
	let imageKeys = await Storage.list(prefix);
	//console.log("imageKeys -> " + JSON.stringify(imageKeys));
	// return images;
	let images = [];
	for (var i = 0; i < imageKeys.length; i++) {
		const imageUrl = await getImages(imageKeys[i]);
		if (imageUrl !== undefined) {
			images.push(imageUrl);
		}
	}
	return images;
};

const getImages = async (element) => {
	if (
		element.key.toLowerCase().includes(".jpeg") ||
		element.key.toLowerCase().includes(".pjpeg") ||
		element.key.toLowerCase().includes(".pjp") ||
		element.key.toLowerCase().includes(DOT_JPG) ||
		element.key.toLowerCase().includes(".png") ||
		element.key.toLowerCase().includes(".svg") ||
		element.key.toLowerCase().includes(".jfif") ||
		element.key.toLowerCase().includes(".webp")
	) {
		// Get the image url
		const imageUrl = await Storage.get(element.key, { level: PUBLIC });
		return imageUrl;
	}
};
