import { Component, Fragment } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import AdditionalDetailsPopup from "./AdditionalDetailsPopup";
import QRPopup from "./QRPopup";
import Popup from 'reactjs-popup';
//Clearkey logo
import logo from "../../assets/img/ClearKeyLogov2White.svg";
import React, { useState } from 'react';

const Menu = (props) => {
  const [qrCodeShow, setQrCodeShow] = useState(false);

  const resetQRCode = () => {
    setQrCodeShow(false);
  }

  const handleClick = () => {
    setQrCodeShow(true);
  };

  return (
    <>
      {/* Logo */}
      <Link className="navbar-brand" to="/">
        <img src={process.env.PUBLIC_URL + logo} alt="logo" />
      </Link>
      {/* Menu */}
      <ul className="navbar-nav">
        <li className={`menu-item menu-item-has-children`}>
          <Link to="/">Home</Link>
        </li>
        <li className={`menu-item menu-item-has-children`}>
          <Link to="/about">About</Link>
        </li>
        {(props.role === "" || !props.loggedin) && (
          <li className={`menu-item menu-item-has-children`}>
            <Link to="/pricing">Pricing</Link>
          </li>
        )}
        <li className={`menu-item menu-item-has-children`}>
          <Link to="/listings">Listings</Link>
          <ul className="submenu">
            <li className="menu-item">
              <Link to="/listings">Browse Listings</Link>
            </li>
            {props.loggedin && (
              <li className="menu-item">
                <Link to="/submit-listing">Submit Listing</Link>
              </li>
            )}
          </ul>
        </li>
        <li className={`menu-item menu-item-has-children`}>
          <Link to="/resources">Resource</Link>
        </li>
        <li className={`menu-item menu-item-has-children`}>
          <Link to="/contact">Contact Us</Link>
        </li>
        {!props.loggedin && (
          <li className={`menu-item menu-item-has-children`}>
            <Link to="/create-ckey">Create CKey</Link>
          </li>
        )}
        {props.loggedin && (
          <li className="menu-item menu-item-has-children">
            {" "}
            <a onClick={handleClick}>CKey</a>{" "}
          </li>
        )}
        {qrCodeShow && <QRPopup onHide={resetQRCode} />}
        {props.loggedin && (
          <li className="menu-item menu-item-has-children">
            {" "}
            <Link to="/dashboard">Dashboard</Link>{" "}
          </li>
        )}
      </ul>
      <AdditionalDetailsPopup />
    </>
  );
};

export default Menu;
