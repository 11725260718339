/**
 * Strings can be messy thus for good hygiene keep all strings in this file
 */

// S3 Strings
export const ACCOUNTS = "accounts";
export const AGENTS = "agents";
export const BIDS = "bids";
export const FILES = "files";
export const LISTINGS = "listings";
export const MEDIA = "media";
export const PUBLIC = "public";

// Property Info
export const BEACH_RIGHT = "beachRight";
export const BEDS = "beds";
export const BULKHEAD = "bulkhead";
export const FULL_BATH = "fullBath";
export const HOME_TYPE = "homeType";
export const LOT_SIZE = "lotSize";
export const SQUARE_FEET = "squareFeet";
export const WATER_VIEW = "waterview";
export const YEAR_BUILT = "yearBuilt";

// AWS-Amplify
export const CLEAR_KEY_BUCKET = "clearkeyimages00639-staging";
export const CLEAR_KEY_REGION = "us-east-1";

//File Formats
export const DOT_JPG = ".jpg";
export const DOT_MP4 = ".mp4";

// Other
export const ID = "id";
export const LISTING_PRICE = "listingPrice";
export const RED = "red";
export const FORWARD_SLASH = "/";

//Status Values
export const STATUS = {
	OPEN : "OPEN",
	PENDING : "PENDING",
	CLOSED : "CLOSED",
	LISTED : "LISTED",
	REMOVED : "REMOVED",
	SOLD : "SOLD",
	DRAFT : "DRAFT",
	PRICE_CHANGE : "PRICE CHANGE",
	COUNTERED_BY_BUYER : "COUNTERED BY BUYER",
	COUNTERED_BY_SELLER : "COUNTERED BY SELLER",
	ACCEPTED_BY_BUYER : "ACCEPTED BY BUYER",
	ACCEPTED_BY_SELLER : "ACCEPTED BY SELLER",
	REJECTED_BY_BUYER : "REJECTED BY BUYER",
	REJECTED_BY_SELLER : "REJECTED BY SELLER",
	CANCELED_BY_BUYER : "CANCELED BY BUYER",
	CANCELED_BY_SELLER : "CANCELED BY SELLER",

}
export const STATUS_OPEN = "OPEN"
export const STATUS_PENDING = "PENDING"
export const STATUS_CLOSED = "CLOSED"
export const STATUS_LISTED = "LISTED"
export const STATUS_REMOVED = "REMOVED"
export const STATUS_SOLD = "SOLD"
export const STATUS_DRAFT = "DRAFT"
export const STATUS_PRICE_CHANGE = "PRICE CHANGE"
export const STATUS_COUNTERED_BY_BUYER = "COUNTERED BY BUYER"
export const STATUS_COUNTERED_BY_SELLER = "COUNTERED BY SELLER"
export const STATUS_ACCEPTED_BY_BUYER = "ACCEPTED BY BUYER"
export const STATUS_ACCEPTED_BY_SELLER = "ACCEPTED BY SELLER"
export const STATUS_REJECTED_BY_BUYER = "REJECTED BY BUYER"
export const STATUS_REJECTED_BY_SELLER = "REJECTED BY SELLER"
export const STATUS_CANCELED_BY_BUYER = "CANCELED BY BUYER"
export const STATUS_CANCELED_BY_SELLER = "CANCELED BY SELLER"

//Response values 
export const RESPONSE_SUCCESSFUL = 'SUCCESSFUL'
export const RESPONSE_NOT_FOUND = 'NOTFOUND'
export const RESPONSE_DUPLICATE = 'DUPLICATE'
export const RESPONSE_FATAL_ERROR = 'FATALERROR' 


// Defaults
export const DEFAULT_DOWN_PAYMENT_PERCENT = 20;
export const DEFAULT_MORTGAGE_INSURANCE_RATE = 2.5;
export const DEFAULT_MORTGAGE_LOAN_INTEREST_RATE = 2.9;
export const DEFAULT_MORTGAGE_LOAN_TERM_YEARS = 30;
export const DEFAULT_PROPERTY_INSURANCE_CHARGE = 0.95;
export const DEFAULT_PROPERTY_TAX_RATE = 2.08;
export const MONTHS_IN_YEAR = 12;
export const DEFAULT_COGNITO_ID = "00000000-0000-0000-0000-000000000000";
export const DEFAULT_AGENT_EMAIL = "dummy_agent@gmail.com";
export const DEFAULT_AGENT_BROKERAGE_NAME = "dummy brokerage name"
export const DEFAULT_DESCRIPTION = "No description provided ...";
export const ONE = 1;
export const DESCENDING_SORT_DIRECTION = "DESC";
export const SORT_DIRECTION = "sortDirection";
export const LIMIT = "limit";
// Address and Location Strings
export const ADDRESS_LINE = "addressLine";
export const CITY = "city";
export const POSTAL_CODE = "postalCode";
export const STATE = "state";
export const STATE_NAMES = {
	arizona: "AZ",
	alabama: "AL",
	alaska: "AK",
	arkansas: "AR",
	california: "CA",
	colorado: "CO",
	connecticut: "CT",
	"district of columbia": "DC",
	delaware: "DE",
	florida: "FL",
	georgia: "GA",
	hawaii: "HI",
	idaho: "ID",
	illinois: "IL",
	indiana: "IN",
	iowa: "IA",
	kansas: "KS",
	kentucky: "KY",
	louisiana: "LA",
	maine: "ME",
	maryland: "MD",
	massachusetts: "MA",
	michigan: "MI",
	minnesota: "MN",
	mississippi: "MS",
	missouri: "MO",
	montana: "MT",
	nebraska: "NE",
	nevada: "NV",
	"new hampshire": "NH",
	"new jersey": "NJ",
	"new mexico": "NM",
	"new york": "NY",
	"north carolina": "NC",
	"north dakota": "ND",
	ohio: "OH",
	oklahoma: "OK",
	oregon: "OR",
	pennsylvania: "PA",
	"rhode island": "RI",
	"south carolina": "SC",
	"south dakota": "SD",
	tennessee: "TN",
	texas: "TX",
	utah: "UT",
	vermont: "VT",
	virginia: "VA",
	washington: "WA",
	"west virginia": "WV",
	wisconsin: "WI",
	wyoming: "WY",
	"american samoa": "AS",
	guam: "GU",
	"northern mariana islands": "MP",
	"puerto rico": "PR",
	"us virgin islands": "VI",
	"us minor outlying islands": "UM",
};
