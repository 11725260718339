import React, { Component, Fragment } from 'react';
import MetaTags from "react-meta-tags";
import Content from '../sections/forgot-password/Content';
import Footer from '../layouts/Footer';

class Forgotpassword extends Component {
    render() {
        return (
            <Fragment>
                <MetaTags>
                    <title>Clear key - innovating the future| Forgot Password</title>
                    <meta
                        name="description"
                        content="#"
                    />
                </MetaTags>
                <Content />
                <Footer />
            </Fragment>
        );
    }
}

export default Forgotpassword;