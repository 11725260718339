import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Auth, API } from "aws-amplify";
import { getCustomer } from "../../graphql/queries";
import { ConsoleLogger } from '@aws-amplify/core';
import QRCode from "qrcode.react";


function QRPopup({ onHide, count })  {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    onHide();
  }

  const handleShow = () => setShow(false);
  
  const [idToken, setIdToken] = useState("");
  const [userCompletedOpenHouseDetails,setUserCompletedOpenHouseDetails] = useState(false);
  const [currentLocation, setCurrentLocation] = useState("");
  
  useEffect(() => {
    // Get current user if logged in
    if (idToken === "") {
        getCurrentUser();
    }
    async function fetchUser() {
        const user = await Auth.currentAuthenticatedUser();
        const customerInfo = await API.graphql({
          query: getCustomer,
          variables: { id: user.attributes.sub },
          authMode: "AWS_IAM",
        });
        console.log(user);
    }
    

    // eslint-disable-next-line
    }, [idToken]);


	const getCurrentUser = async () => {
		const data = (await Auth.currentSession()).getIdToken();
		const user = await Auth.currentAuthenticatedUser();
        setIdToken(data);

        const obj = await API.graphql({
            query: getCustomer,
            variables: {
                id: user.attributes.sub,
            },
            authMode: "AWS_IAM",
        });
        let rentOrOwn = obj.data.getCustomer.rentOrOwn;
        console.log(obj.data.getCustomer);
        setCurrentLocation(window.location+"_link_"+user.attributes.sub)
        if (rentOrOwn === null){
            setShow(true);
        }
        else{
            
            setUserCompletedOpenHouseDetails(true);
        }
        console.log(rentOrOwn);
	};


    return (
        <>
        
        {
                userCompletedOpenHouseDetails === false && (
                <Modal show={show} onHide={handleClose} >
                    <Modal.Header closeButton>
                    <Modal.Title>QR Code</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <QRCode 
						size = "180" value = {currentLocation}/>
                    </Modal.Body>
                    <Modal.Footer>
                    
                    </Modal.Footer>
                </Modal>
                )
        }
        </>
    );
        
    
}
export default QRPopup;

