import React, { useState, useRef, Fragment } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";

/* Login Page needs email and password to get in*/

export default function Content(props) {
	const [submitMode, setSubmitMode] = useState("SendCode");
	//const [submitMode, setSubmitMode] = useState('ChangeSuccessful')
	const { register, handleSubmit, setError, errors, watch } = useForm();
	const [passwordStatus, setPasswordStatus] = useState({
		validLength: false,
		hasNumber: false,
		hasUpper: false,
		hasLower: false,
		hasSpecial: false,
	});

	const [confirmPasswordStatus, setConfirmPasswordStatus] = useState(false);
	const [codeStatus, setCodeStatus] = useState(false);
	const [email, setEmail] = useState();

	const password = useRef({});
	const confirmPassword = useRef({});
	password.current = watch("password", "");
	confirmPassword.current = watch("confirmPassword", "");

	const digits = /^[-+]?[0-9]+$/;

	const onSubmitForm = async (data, event) => {
		event.preventDefault();
		switch (submitMode) {
			case "SendCode":
				try {
					const cc1 = await Auth.forgotPassword(data.email);
					console.log(cc1);
					setSubmitMode("ChangePassword");
					setEmail(data.email);
				} catch (e) {
					console.log(e);
					setError("server", {
						type: e.code,
						message: e.message,
					});
				}
				break;
			case "ChangePassword":
				try {
					const cc2 = await Auth.forgotPasswordSubmit(data.email, data.code, data.password);
					console.log(cc2);
					setSubmitMode("ChangeSuccessful");
				} catch (e) {
					console.log(e);
					setError("server", {
						type: e.code,
						message: e.message,
					});
				}
				break;
			default:
		}
	};

	const requiredStyle = {
		verticalAlign: "middle",
		marginRight: "1em",
		color: "crimson",
	};
	const completedStyle = {
		verticalAlign: "middle",
		marginRight: "1em",
		color: "green",
	};

	const errorStyle = {
		color: "#b30000",
		marginRight: "1em",
		display: "block",
	};

	const correctStyle = {
		color: "#00cc00",
		marginRight: "1em",
		display: "block",
	};

	const ruleStyle = {
		color: "#404040",
		marginLeft: "1em",
		fontWeight: "400",
	};

	function handleChange(event) {
		let v = event.target.value.trim();
		switch (event.target.name) {
			case "code":
				setCodeStatus(v.length === 6 && v.match(digits));
				break;
			case "password":
				let newStatus = Object.assign({}, passwordStatus);
				newStatus.validLength = v.length >= 8 && v.length <= 25;
				newStatus.hasNumber = /\d+/.test(v);
				newStatus.hasUpper = /[A-Z]/.test(v);
				newStatus.hasLower = /[a-z]/.test(v);
				newStatus.hasSpecial = /[\s~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?()._]/g.test(v);
				setPasswordStatus(newStatus);
				setConfirmPasswordStatus(v === confirmPassword.current);
				break;

			case "confirmPassword":
				setConfirmPasswordStatus(
					v === password.current && Object.values(passwordStatus).every(Boolean)
				);
				break;

			default: //Do nothing
		}
	}

	const handleClick = async (event) => {
		event.preventDefault();
		try {
			const cc1 = await Auth.forgotPassword(email);
			console.log(cc1);
		} catch (e) {
			console.log(e);
			setError("server", {
				type: e.code,
				message: e.message,
			});
		}
	};

	return (
		<div className="acr-auth-container">
			<div className="acr-auth-content">
				{submitMode !== "ChangeSuccessful" && (
					<form onSubmit={handleSubmit(onSubmitForm)}>
						<div className="auth-text">
							<h3>Forgot your password?</h3>
							<p>Tell us your email address, and we'll get you back on track in no time. </p>
						</div>
						{errors.server && <span className="text-danger">{errors.server.message}</span>}

						<div className="form-group">
							<label>Email Address</label>
							<input
								type="email"
								readOnly={submitMode === "ChangePassword"}
								className="form-control form-control-light"
								name="email"
								ref={register({
									required: {
										value: true,
										message: "email is required",
									},
								})}
							/>
							{errors.email && <span className="text-danger">{errors.email.message}</span>}
						</div>

						{submitMode === "ChangePassword" && (
							<Fragment>
								<div>
									<p>
										Verification Code was sent to the phone number associated with your account
										<br />
										Enter verification code and new password below. Click{" "}
										<Link style={{ cursor: "pointer" }} to="/" onClick={handleClick}>
											here
										</Link>{" "}
										to resend.
									</p>
								</div>

								<div className="form-group">
									<i
										className={codeStatus ? "fas fa-check" : "fas fa-asterisk"}
										style={codeStatus ? completedStyle : requiredStyle}></i>
									<label>Verification Code</label>

									<input
										type="text"
										className="form-control form-control-light"
										name="code"
										maxLength="6"
										ref={register}
										onChange={handleChange}
									/>
								</div>
								<div className="form-group">
									<i
										className={
											Object.values(passwordStatus).every(Boolean)
												? "fas fa-check"
												: "fas fa-asterisk"
										}
										style={
											Object.values(passwordStatus).every(Boolean) ? completedStyle : requiredStyle
										}></i>
									<label>Password</label>
									<input
										type="password"
										className="form-control form-control-light"
										autoComplete="on"
										name="password"
										ref={register}
										onChange={handleChange}
									/>
									<div style={{ textIndent: "50px", marginTop: "1em" }}>
										<p>
											<i
												className={
													passwordStatus.validLength ? "fas fa-check" : "fas fa-times-circle"
												}
												style={passwordStatus.validLength ? correctStyle : errorStyle}>
												<span style={ruleStyle}>Between 8 and 25 characters</span>
											</i>
										</p>
										<p>
											<i
												className={
													passwordStatus.hasNumber ? "fas fa-check" : "fas fa-times-circle"
												}
												style={passwordStatus.hasNumber ? correctStyle : errorStyle}>
												<span style={ruleStyle}>At least one number</span>
											</i>
										</p>
										<p>
											<i
												className={passwordStatus.hasUpper ? "fas fa-check" : "fas fa-times-circle"}
												style={passwordStatus.hasUpper ? correctStyle : errorStyle}>
												<span style={ruleStyle}>At least one capital letter</span>
											</i>
										</p>
										<p>
											<i
												className={passwordStatus.hasLower ? "fas fa-check" : "fas fa-times-circle"}
												style={passwordStatus.hasLower ? correctStyle : errorStyle}>
												<span style={ruleStyle}>At least one lowercase character</span>
											</i>
										</p>
										<p>
											<i
												className={
													passwordStatus.hasSpecial ? "fas fa-check" : "fas fa-times-circle"
												}
												style={passwordStatus.hasSpecial ? correctStyle : errorStyle}>
												<span style={ruleStyle}>At least one special character</span>
											</i>
										</p>
									</div>
								</div>

								<div className="form-group">
									<i
										className={confirmPasswordStatus ? "fas fa-check" : "fas fa-asterisk"}
										style={confirmPasswordStatus ? completedStyle : requiredStyle}></i>
									<label>Confirm Password</label>
									<input
										type="password"
										className="form-control form-control-light"
										autoComplete="on"
										name="confirmPassword"
										ref={register}
										onChange={handleChange}
									/>
								</div>
							</Fragment>
						)}

						{submitMode === "SendCode" && (
							<button type="submit" className="btn-custom secondary btn-block">
								Send Verification Code
							</button>
						)}

						{submitMode === "ChangePassword" && (
							<button
								type="submit"
								className="btn-custom secondary btn-block"
								disabled={
									!(
										codeStatus &&
										Object.values(passwordStatus).every(Boolean) &&
										confirmPasswordStatus
									)
								}>
								Change Password
							</button>
						)}

						<div className="auth-seperator">
							<span>OR</span>
						</div>

						<p className="text-center mb-0">
							<Link to="/login">Back to sign in</Link>
						</p>
					</form>
				)}
				{submitMode === "ChangeSuccessful" && (
					<h6>
						Your password has been changed. You can now proceed to <Link to="/login">Login.</Link>
					</h6>
				)}
			</div>
		</div>
	);
}
