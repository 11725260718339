import React, { Fragment } from "react";
import Menu from "./Menu";
import Mobilemenu from "./Mobilemenu";
import { UserDropdown } from "./UserDropdown";
import HeaderComponent from "../../helper/Navigationhelper";
import { Link, withRouter } from "react-router-dom";
import classNames from "classnames";
import CookieNotification from "./Cookie-Banner";
import { Auth, API } from "aws-amplify";
import { getCustomer } from "../../graphql/queries";

class Header extends HeaderComponent {
  constructor() {
    super();
    this.state = {
      loggedIn: false,
      user: null,
      show: true,
    };
  }

  checkIfLoggedIn = async () => {
    try {
      let r = await Auth.currentAuthenticatedUser();
      const obj = await API.graphql({
        query: getCustomer,
        variables: {
          id: r.attributes.sub,
        },
        authMode: "AWS_IAM",
      });
      console.log(obj);
      this.setState({
        loggedIn: true,
        show: true,
        user: r,
        role: obj.data.getCustomer.role,
      });

    } catch {
      this.setState({ loggedIn: false });
    }
  };

  componentDidMount() {
    this.checkIfLoggedIn();
  }

  render() {
    const { loggedIn, user, show } = this.state;
    const {
      location: { pathname },
    } = this.props;
    const isHomePage = pathname === "/";
    console.log(this.state);

    return (
      <Fragment>
        <CookieNotification />
        {/* Aside (Mobile Navigation) */}
        <aside
          className={classNames("main-aside", { open: this.state.navtoggle })}
        >
          <div className="aside-title">
            <div className={"aside-controls aside-trigger"}>
              <div className="title text-white">Menu</div>
              <div className="close-btn close" onClick={this.navtoggleClass}>
                <span />
                <span />
              </div>
            </div>
          </div>
          <Mobilemenu navtoggleClass={this.navtoggleClass} />
        </aside>
        <div
          className={"aside-overlay aside-trigger"}
          onClick={this.navtoggleClass}
        />
        {/* Header Start */}
        <header className={"header-3"}>
          <div
            className={"main-header " + (isHomePage ? "main-header__home" : "")}
          >
            <nav className="navbar">
              <div className={"container"}>
                {/* Menu */}
                <Menu
                  show={this.state.show}
                  loggedin={loggedIn}
                  isHomePage={isHomePage}
                  role={this.state.role}
                />
                <div className="header-controls">
                  <ul className="header-controls-inner d-none d-lg-flex">
                    <li>
                      {loggedIn ? (
                        <UserDropdown user={user} />
                      ) : (
                        <div>

                          <Link to="/login" className="btn-custom red">
                            Log In
                          </Link>


                        </div>
                      )}
                    </li>
                  </ul>
                  {/* Toggler */}
                  <div
                    className={
                      "aside-toggler aside-trigger " +
                      (!isHomePage ? "aside-trigger__dark" : "")
                    }
                    onClick={this.navtoggleClass}
                  >
                    <span />
                    <span />
                    <span />
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </header>
        {/* Header End */}
      </Fragment>
    );
  }
}

export default withRouter(Header);
