import { ACCOUNTS, BIDS, FILES, FORWARD_SLASH, LISTINGS, MEDIA } from "../constants/constants";

export function preapprovalS3Prefix(customerID, listingId) {
	return (
		ACCOUNTS +
		FORWARD_SLASH +
		customerID +
		FORWARD_SLASH +
		BIDS +
		FORWARD_SLASH +
		listingId +
		FORWARD_SLASH +
		FILES +
		FORWARD_SLASH
	);
}

export function accountProfilePictureS3Prefix(customerID) {
	return ACCOUNTS + FORWARD_SLASH + customerID + FORWARD_SLASH + "profile" + FORWARD_SLASH;
}

export function agentProfilePictureS3Prefix(agentID) {
	return ACCOUNTS + FORWARD_SLASH + agentID + FORWARD_SLASH + "profile" + FORWARD_SLASH;
}

export function listingPictureS3Prefix(listingID) {
	return LISTINGS + FORWARD_SLASH + listingID + FORWARD_SLASH + "media" + FORWARD_SLASH;
}

export function propertyImageS3Prefix(propertyID) {
	return LISTINGS + FORWARD_SLASH + propertyID + FORWARD_SLASH + MEDIA + FORWARD_SLASH;
}
