import React, { Suspense } from "react";
import {
  Redirect,
  withRouter,
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import { Auth } from "aws-amplify";
import Forgotpassword from "./components/pages/Forgotpassword";
import Header from "./components/layouts/Header";

// Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));

// Home Pages
const Home = React.lazy(() => import("./components/pages/Home"));
// Blog
const Bloggrid = React.lazy(() => import("./components/pages/Bloggrid"));
const Bloglist = React.lazy(() => import("./components/pages/Bloglist"));
const Blogsingle = React.lazy(() => import("./components/pages/Blogsingle"));
// Pages
const About = React.lazy(() => import("./components/pages/About"));
const Cookiepolicy = React.lazy(() =>
  import("./components/pages/Cookiepolicy")
);
const Termsofservice = React.lazy(() =>
  import("./components/pages/Termsofservice")
);
const Sellersagreement = React.lazy(() =>
  import("./components/pages/Sellersagreement")
);
const Buyersagreement = React.lazy(() =>
  import("./components/pages/Buyersagreement")
);
const Trademark = React.lazy(() => import("./components/pages/Trademark"));
const Privacypolicy = React.lazy(() =>
  import("./components/pages/Privacypolicy")
);
const FairHousingStatement = React.lazy(() =>
  import("./components/pages/FairHousingStatement")
);
const Services = React.lazy(() => import("./components/pages/Services"));
const Faq = React.lazy(() => import("./components/pages/Faq"));
const Resources = React.lazy(() => import("./components/pages/Resources"));
const Pricing = React.lazy(() => import("./components/pages/Pricing"));

const FSBOCheckout = React.lazy(() =>
  import("./components/pages/PricingCheckout/FSBOCheckout")
);
const BuyerCheckout = React.lazy(() =>
  import("./components/pages/PricingCheckout/BuyerCheckout")
);
const AgentCheckout = React.lazy(() =>
  import("./components/pages/PricingCheckout/AgentCheckout")
);
const ProCheckout = React.lazy(() =>
  import("./components/pages/PricingCheckout/ProCheckout")
);
const ProProCheckout = React.lazy(() =>
  import("./components/pages/PricingCheckout/PlatformCheckout")
);
const AdditonalDetailsCheckout = React.lazy(() =>
  import("./components/pages/PricingCheckout/BrokerDetails")
);

const SelectAgentCheckout = React.lazy(() =>
  import("./components/pages/PricingCheckout/SelectAgentCheckout")
);

const Contact = React.lazy(() => import("./components/pages/Contact"));
const Comingsoon = React.lazy(() => import("./components/pages/Comingsoon"));
const Error = React.lazy(() => import("./components/pages/Error"));
const Login = React.lazy(() => import("./components/pages/Login"));
const Register = React.lazy(() => import("./components/pages/Register"));
// Listings
const Listings = React.lazy(() => import("./components/pages/Listings"));
const Listingdetailsone = React.lazy(() =>
  import("./components/pages/Listingdetailsone")
);

const Listingdetailstwo = React.lazy(() =>
  import("./components/pages/Listingdetailstwo")
);
const Submitlisting = React.lazy(() =>
  import("./components/pages/Submitlisting")
);
const Comparelistings = React.lazy(() =>
  import("./components/pages/Comparelistings")
);
//Bidding
const Submitbid = React.lazy(() => import("./components/pages/Submitbid"));
// Agents
const Agentarchive = React.lazy(() =>
  import("./components/pages/Agentarchive")
);
const Agentdetails = React.lazy(() =>
  import("./components/pages/Agentdetails")
);
const Profile = React.lazy(() => import("./components/pages/Profile"));

const Scanner = React.lazy(() => import("./components/pages/Scanner"));

const OpenHouseQuestions = React.lazy(() => import("./components/pages/OpenHouseQuestionnaire"));


const Profilelistings = React.lazy(() =>
  import("./components/pages/Profilelistings")
);
const Profilesavedlistings = React.lazy(() =>
  import("./components/pages/Profilesavedlistings")
);
// Agency
const Agencyarchive = React.lazy(() =>
  import("./components/pages/Agencyarchive")
);
const Agencydetails = React.lazy(() =>
  import("./components/pages/Agencydetails")
);
// Dashboard
const Dashboard = React.lazy(() => import("./components/pages/Dashboard"));

// QR CODE
const QRCode = React.lazy(() => import("./components/pages/QRCode"));

// CREATE QR CODE
const CreateQRCode = React.lazy(() => import("./components/pages/CreateQRCode"));

//Test
// const TestUploadImage = React.lazy(() => import("./components/pages/Imageuploadtest"));
const Result = React.lazy(() => import("./components/pages/Result"));
const PaymentResults = React.lazy(() =>
  import("./components/pages/ResultPayments")
);
//const TestPage = React.lazy(() => import("./components/sections/dashboard/Test"));
const Editlisting = React.lazy(() => import("./components/pages/Editlisting"));

const TermSheet = React.lazy(() => import("./components/pages/PDFs/TermSheet"));

class PrivateRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false,
  };
  componentDidMount() {
    this.authenticate();
    this.unlisten = this.props.history.listen(() => {
      Auth.currentAuthenticatedUser()
        .then((user) => console.log("login successful"))
        .catch(() => {
          if (this.state.isAuthenticated)
            this.setState({ isAuthenticated: false });
        });
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }
  authenticate() {
    Auth.currentAuthenticatedUser()
      .then(() => {
        this.setState({ loaded: true, isAuthenticated: true });
      })
      .catch(() => this.props.history.push("/login"));
  }
  render() {
    const { component: Component, ...rest } = this.props;
    const { loaded, isAuthenticated } = this.state;
    if (!loaded) return null;
    return (
      <Route
        {...rest}
        render={(props) => {
          return isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
              }}
            />
          );
        }}
      />
    );
  }
}

PrivateRoute = withRouter(PrivateRoute);

export default function Routes() {
  return (
    <Router>
      <Header />
      <Suspense fallback={<div></div>}>
        <Preloader />
        <Switch>
          {/* Homepages */}
          <Route exact path="/" component={Home} />
          {/* Blog */}
          <Route path="/blog-grid" component={Bloggrid} />
          <Route path="/blog-list" component={Bloglist} />
          <Route path="/blog-single" component={Blogsingle} />
          {/* Pages */}
          <Route path="/about" component={About} />
          <Route path="/legal/cookie_policy" component={Cookiepolicy} />
          <Route path="/legal/terms_of_service" component={Termsofservice} />
          <Route path="/legal/buyers_agreement" component={Buyersagreement} />
          <Route path="/legal/sellers_agreement" component={Sellersagreement} />
          <Route path="/legal/trademark" component={Trademark} />
          <Route
            path="/legal/fair_housing_statement"
            component={FairHousingStatement}
          />
          <Route path="/legal/privacy_policy" component={Privacypolicy} />
          <Route path="/services" component={Services} />
          <Route path="/faq" component={Faq} />
          <Route path="/resources" component={Resources} />
          <Route path="/pricing" component={Pricing} />

          <PrivateRoute path="/fsbo-checkout" component={FSBOCheckout} />
          <PrivateRoute path="/buyer-checkout" component={BuyerCheckout} />
          <PrivateRoute path="/agent-checkout" component={AgentCheckout} />
          <PrivateRoute path="/pro-checkout" component={ProCheckout} />
          <PrivateRoute path="/platform-checkout" component={ProProCheckout} />
          <PrivateRoute
            path="/additional-details"
            component={AdditonalDetailsCheckout}
          />
          <PrivateRoute path="/select-agents" component={SelectAgentCheckout} />

          <Route path="/contact" component={Contact} />
          <Route path="/coming-soon" component={Comingsoon} />
          <Route path="/login" component={Login} />
          <Route
            path="/register/:confirmationNeeded?/:username?"
            component={Register}
          />
          <Route path="/forgotpassword" component={Forgotpassword} />
          {/* Listings */}
          <Route path="/listings" component={Listings} />
          {/* sending propertyID in link*/}
          <Route
            path="/listing-details-v1/:propertyID"
            component={Listingdetailsone}
          />
          <Route
            path="/listing-details-v2/:propertyID"
            component={Listingdetailstwo}
          />
          <PrivateRoute path="/submit-listing" component={Submitlisting} />
          <Route path="/compare-listings" component={Comparelistings} />
          {/* Bids */}
          <PrivateRoute path="/submit-bid/:listingId" component={Submitbid} />
          {/* Agents */}
          <Route path="/agent-archive" component={Agentarchive} />
          <Route path="/agent-details" component={Agentdetails} />
          <PrivateRoute path="/profile" component={Profile} />

          <Route path="/open-house-checkin" component={Scanner} />
          <Route path='/open-house-questions' component={OpenHouseQuestions} />


          <Route path="/profile-listings" component={Profilelistings} />
          <Route
            path="/profile-saved-listings"
            component={Profilesavedlistings}
          />
          {/* Agency */}
          <Route path="/agency-archive" component={Agencyarchive} />
          <Route path="/agency-details" component={Agencydetails} />
          {/* Dashboard */}
          <PrivateRoute path="/dashboard" component={Dashboard} />

          {/* QR Code */}
          <PrivateRoute path="/ckey" component={QRCode} />

          <Route path="/create-ckey" component={CreateQRCode} />

          {/* Dev */}
          {/* <PrivateRoute path="/image-upload" component={TestUploadImage} /> */}
          <Route path="/result" component={Result} />
          <PrivateRoute path="/payment-result" component={PaymentResults} />
          {/* <Route path="/test-page" component={TestPage} /> */}
          <PrivateRoute path="/edit-listing" component={Editlisting} />
          <PrivateRoute path="/term-sheet/:offerId" component={TermSheet} />

          {/* 404 Page - Catch all other paths */}
          <Route component={Error} />
        </Switch>
      </Suspense>
    </Router>
  );
}
